import { createAPI } from "../utils/request"

export const departDelete = data => createAPI(`base/depart/delete/${data.id}`, 'get', data) //删除科室信息

export const publishList = data => createAPI('/base/publish/list', 'get', data) //查询发布包列表

export const publishAdd = (data,onUploadProgress) => createAPI('/base/publish/create', 'post', data,{onUploadProgress}) //新增发布包

export const publishApp = data => createAPI(`/base/publish/publish/${data.id}`, 'get', data) //发布安装包

export const publishDelete = data => createAPI(`/base/publish/delete/${data.id}`, 'get', data) //删除安装包

export const publishGet = data => createAPI(`/base/publish/get/${data.id}`, 'get', data) //获取安装包详情

export const publishDown = data => createAPI(`/base/publish/download/${data.id}`, 'get', data) //下载安装包信息

export const publishLatest = data => createAPI('/base/publish/get/latest', 'get', data) // 查看安装包最新版本信息






