<template>
  <div id="publish" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="基础数据管理" icon="iconfont icon-ziyuan143" />
      <q-breadcrumbs-el label="发布管理" icon="iconfont icon-fabu" to="/publish/list" />
    </q-breadcrumbs>
    <div class="publishbox" id="publishbox">
      <!-- 搜索组件 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <!-- <q-input class='operation' :dense='true' outlined  v-model="queryData.patientName" label="应用名称">
                <template v-if="queryData.patientName" v-slot:append>
                  <q-icon name="close" @click.stop="queryData.patientName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input> -->
              <selectq class='operation' :options='apkType' :model.sync='apkName' :label='"应用名称"' />
              <selectq class='operation' :options='osType' :model.sync='apkOsType' :label='"资源类型"' />
              <el-button
                :disabled='Permissionslist.filter(item=>item==="apk.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="apk.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
            </div>
            <div class="col  addbtn">
              <!-- :disabled='multipleSelection.length==0? true : false' -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="apk.add").length>0?false:true'
                type="primary"
                size='medium'
                @click='addApp'
              >
              新增
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 表格区 -->
      <q-card class="my-card">
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="Publishlist"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"  
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column  show-overflow-tooltip align='center' prop="apkName" label="应用名称" min-width='93px'></el-table-column>
          <el-table-column  align='center' prop="apkVersion" label="版本号" min-width='104px'></el-table-column>
          <el-table-column show-overflow-tooltip align='center' prop="publishComment" label="发布说明" min-width='104px'></el-table-column>
          <el-table-column  align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
          <!-- 发布类型 -->
          <el-table-column align='center' prop="publishType" label="发布类型" min-width="143px" >
            <template slot-scope="scope">
                {{ (publishType.filter(item=>item.key == scope.row.publishType).pop() || {} ).value }}
            </template>
          </el-table-column>
          <!-- 发布状态 -->
          <el-table-column align='center' prop="publishStatus" label="发布状态" min-width="90px" >
            <template slot-scope="scope">
                {{ (publishStatus.filter(item=>item.key == scope.row.publishStatus).pop() || {} ).value }}
            </template>
          </el-table-column>
          <el-table-column align='center' label="操作" width="250" fixed="right">
            <template slot-scope="scope">
              <!-- 领取 -->
              <q-btn  
                :disable='Permissionslist.filter(item=>item==="apk.add").length>0 && scope.row.publishStatus==0? false : true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-fabu" 
                @click='ReleaseApp(scope.row)'>
                <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                  发布
                </q-tooltip>  
              </q-btn> 
              <!-- 审核@click='closeApp' -->
              <q-btn
                class="czbtn"  
                size="sm" 
                color="yellow-8" 
                icon="iconfont icon-xiazai" 
                @click='downApp(scope.row)'
              >
                <q-tooltip content-class="bg-yellow-8" :offset="[10, 10]">
                  下载
                </q-tooltip>  
              </q-btn>

              <q-btn
                :disable='Permissionslist.filter(item=>item==="apk.delete").length>0 && scope.row.publishStatus==0? false : true'
                class="czbtn"  
                size="sm" 
                color="red-7" 
                icon="iconfont icon-ashbin" 
                @click='delApp(scope.row)'
              >
                <q-tooltip content-class="bg-red-7" :offset="[10, 10]">
                  删除
                </q-tooltip>  
              </q-btn>
            </template>
          </el-table-column>
          
        </el-table>
         <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getPublishlist()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>

      <!-- app新增发布弹窗 -->
      <el-dialog :visible.sync="releaseconfirm" width="35%" title="应用发布" :close-on-click-modal='false' @close="closerelease">
        <release
          class="el-dialog__body"
          ref='addrelease'
          :addorderdata.sync='agreeData'
          @closedialog='closerelease'
          @ReleaseSuccess='getPublishlist()'
        >
        </release>
      </el-dialog> 

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import release from '@/components/Publish/release' // APP新增弹窗
import { makeDebounce } from "@/utils/debounce.js";  
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口

import { publishList,publishDown,publishApp,publishDelete } from '@/api/Publish.js'; // 用户订单增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getPublishlist()
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.search()
      }  
    }
  },
  data() {
    return {
      tableHeight:0,
      
      ordervisible: false, //控制表格loading
      nodatetype:'', //无内容时显示
      // 分页设置
      queryData:{ // element分页信息
          page: 1,
          pageSize: 10,
          // orderId: '', // 订单号
      },
      sortField:'', // 控制那列排序
      sortType:'', // 控制排序类型
      
      apkName:'', // 应用名称
      apkOsType:'', //系统类型查询  
      total:0, //总条数

      Publishlist:[], //资料审核列表数据

      releaseconfirm: false, //控制app发布
      agreeData:{ // 查看发布app时所需信息
        apkName:'', //应用名称
        osTypeid:'', //储存系统类型
        apkVersion:'', //版本号
        publishTypeid:'', //发布类型
        publishComment:'', //发布说明
        dcmReportFilesList:[],//apk包
      },

    };
  },
  components:{
    elementPagination, //分页组件
    selectq,
    release, //发布app页面
  },
  computed:{
  ...mapState([
      'apkType',//应用名称
      'publishStatus',//发布状态
      'publishType',//发布类型
      'osType',//系统类型
      'Permissionslist' // 权限词典
    ]),
  },
  watch:{
  },
  methods: {
    // setTimeout(async() =>{},500)
    getPublishlist(){ //获取资源审核列表
      this.nodatetype=''
      this.ordervisible = true
      setTimeout(async() =>{
        const { data: res } = await publishList({
          ...this.queryData,
          apkOsType: this.apkOsType.key,
          apkName: this.apkName.key,
        })
        console.log(res);
        if(res.code===20000){
          this.Publishlist = res.data.apks
          this.total = res.data.totals * 1
        }
        this.ordervisible = false
        this.nodatetype='暂无数据'

        let height = document.getElementById('searchbox').offsetHeight + 85
        this.tableHeight = getHeight('publishbox',height)
      },500)
      
    },
    // 新增app
    addApp(){ 
      this.releaseconfirm = true
    },
    // 新增弹窗关闭时
    closerelease(){
      this.agreeData.apkName=''
      this.agreeData.osTypeid=''
      this.agreeData.apkVersion=''
      this.agreeData.publishTypeid=''
      this.agreeData.publishComment=''
      this.agreeData.dcmReportFilesList=[]
      this.$refs.addrelease.$refs.myForm.resetValidation()
      this.releaseconfirm = false
    },
    // 下载
    async downApp(row){
      console.log(row);
      this.$q.dialog({
        title: '下载',
        message: `你确定要下载此app安装包`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            // const { data: res } = await publishDown({ id: row.id })
            const url = window.origin +
                "/base/publish/download/" +
                encodeURIComponent(row.id) 
                window.open(url);
            console.log(url);
            // console.log(res);
            if(res.code===20000){
              this.getPublishlist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    /**
     * 点击删除
     */
    delApp(row){
        this.$q.dialog({
            title: '删除',
            message: `你确定要删除此安装包吗?`,
            ok: { label: '确定'},
            cancel: { label: '取消',flat: true},
        }).onOk(async() => {
            try{
                const { data: res } = await publishDelete({ id: row.id})
                console.log(res);
                if(res.code===20000){
                  this.getPublishlist()
                  notifys('positive',res.message)
                }else{
                  notifys('negative',res.message)
                }
            }catch (e){
                console.log(e);
            }
        })
        console.log('点击了删除');
    },
    /**
     * 点击发布
     */
    ReleaseApp(row){
      this.$q.dialog({
        title: '发布',
        message: `你确定要发布此安装包吗?`, 
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await publishApp({ id: row.id})
            console.log(res);
            if(res.code===20000){
              this.getPublishlist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
        }catch (e){
          console.log(e);
        } 
      })
    },
    // 点击重置
    chongzhi:makeDebounce(function(){
      this.queryData.page = 1
      this.apkName = ''
      this.apkOsType =''
      this.getPublishlist()
    },200),
    //点击搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.getPublishlist()
    },200),
    // 分页被修改时
    changepageSize(val){
      this.queryData.pageSize = val
      this.getPublishlist()
    },
    changePage(val){
      this.queryData.page = val
      this.getPublishlist()
    }
    
  },
};
</script>

<style lang='less' scoped>
#publish{
  height: 100%;
}
.publishbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
  align-items: flex-end;
  flex-wrap: wrap;
  .operation{
    margin: .625rem .625rem 0 .625rem;
    width: 15.625rem;
  }
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.resetquery{
  margin: .625rem .625rem 0 .625rem;
}
.czbtn{
  margin-right: .75rem;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.upload{
  display: none;
}
/deep/.el-dialog__body{
  padding: 10px 10px !important;
}
</style>
