<template>
  <div>
    <q-form ref="myForm" greedy class="q-gutter-md add">
      <q-card flat bordered class="my-card add">
        <q-card-section>
          <!-- 应用名称 -->
          <selectq class='add' :options='apkType' :model.sync='getadddata.apkName' :label='"应用名称*"' :rule='required.apkNameRule'/>

          <!-- 系统类型 -->
          <selectq class='add' :options='osType' :model.sync='getadddata.osTypeid' :label='"系统类型*"' :rule='required.osTypeRule'/>

          <!-- 发布类型 -->
          <selectq class='add' :options='publishType' :model.sync='getadddata.publishTypeid' :label='"发布类型*"' :rule='required.publishTypeRule'/>
          
          <!-- 版本号 -->
          <q-input class="add" mask="####" :loading="loadingState" :dense='true' outlined  v-model="getadddata.apkVersion" label="版本号*" lazy-rules :rules='[required.apkVersionRule]'>
              <template v-slot:append>
                <q-btn v-if="!loadingState" flat color="primary" label="获取最新版本号" size="xs" @click="getLatest"/>
              </template>
          </q-input>

          <!-- 发布说明 -->
          <q-input
            class='add'
            :dense='true' 
            outlined  
            autogrow
            type="textarea"
            v-model="getadddata.publishComment" 
            label="发布说明"
            :rules="[required.publishCommentRule]"
          />
        </q-card-section>
      </q-card>
      <q-card flat bordered class="my-card add">
        <q-card-section>
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            accept=".apk"
            :on-change='dcmReportFileschange'
            :on-remove="dcmReportFilesRemove"
            :file-list="getadddata.dcmReportFilesList"
            :auto-upload="false"
            :multiple='false'
            >
            <el-button slot="trigger" size="small" type="primary">选取APP安装包</el-button>
            <div slot="tip" class="el-upload__tip">只支持apk的文件</div>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-upload>
        </q-card-section>
      </q-card>
      <div class="addbtn">
        <el-popover
          placement="bottom"
          width="155"
          v-model="visible">
          <p>未保存的数据将被丢弃, 是否继续?</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
          </div>
          <el-button slot="reference">取消</el-button>
        </el-popover>
          <!-- <q-btn
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          /> -->
          <el-button class="xz" slot="reference" type="primary" @click="addorder" >发布</el-button>
          <!-- <el-button slot="reference" type="primary" @click="addcommitsorder" >保存并提交</el-button> -->
          <!-- <q-btn
              label="添加"
              color="blue-6"
              @click="addorder"
          /> -->
      </div>
    </q-form>

    <!-- 上传文件提示区 -->
    <q-dialog v-model="alert" persistent>
      <q-card style="width: 400px;">
        <q-card-section>
          <div class="wenjian">文件: {{fileName}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-linear-progress size="25px" :value="checkprogress" color="primary">
            <div class="absolute-full flex flex-center">
              <q-badge color="white" text-color="primary" :label="checkprogressLabel" />
            </div>
          </q-linear-progress>
        </q-card-section>
        <q-card-section class="q-pt-none">
          
        </q-card-section>
      </q-card>
    </q-dialog>
    
  </div>
</template>

<script>
import { mapState } from 'vuex' //vuex获取数据
import { publishLatest,publishAdd } from '@/api/Publish.js'; // 用户订单增删改查接口

import axios from 'axios'
import { makeDebounce } from "@/utils/debounce.js";  

import selectq from '@/components/Order/select' // 普通下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
  props:{
    addorderdata: { // 新增订单信息
      type: Object,
      required: true
    }
  },
  created(){
  },
  data() {
    return {
      visible: false, //控制取消是否关闭弹窗
      loadingState: false, //控制查询版本号加载是否出现 控制查询按钮是否出现

      required, //表单验证规则

      // orderTypemodel: '', // 订单类型选择
      // ispaymodel: '', //代理商是否付款选择,
      checkReportFilesList: [], //检查报告储存信息
      dcmReportFilesList: [], // 影像资料储存信息
      addmassage: '',
      alert: false, // 控制上传文件提示

      checkprogress: 0, //进度条
      checkprogressLabel: '', //进度百分比
      fileName: '', // 文件名

    //   addcommit: false, // 控制是否触发提交
    };
  },
  components:{
    selectq, // 普通下拉组件
  },
  computed:{
    ...mapState([
      'apkType',//应用名称
      'publishStatus',//发布状态
      'publishType',//发布类型
      'osType',//系统类型
      'Permissionslist' // 权限词典
    ]),
    getadddata:{
      get(){
          return this.addorderdata;
      },
      set(v){
          this.$emit('update:addorderdata',v)
      }
    }
  },
  watch:{
  },
  methods: {
    // 处理传输参数form和json格式问题
    getURLParamsFromObject(obj) {
      const form = new URLSearchParams()
      for (const [key, value] of Object.entries(obj)) {
          if (value != null) {
              form.set(key, (value).toString())
          }
      }
      return form
    },
    // 处理传输参数form和json格式问题
    getURLParamsStringFromObject(obj) {
        return getURLParamsFromObject(obj).toString()
    },

    //影像资料改变时
    dcmReportFileschange(file, fileList){
      let fileName = file.name;
      let uid = file.uid
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      this.getadddata.dcmReportFilesList =[]
      return this.getadddata.dcmReportFilesList.push(file.raw)
    },
    //影像资料删除时
    dcmReportFilesRemove(file,fileList){
      console.log(file);
      const index = this.getadddata.dcmReportFilesList.findIndex(item => item.uid === file.uid)
      console.log(index);
      this.getadddata.dcmReportFilesList.splice(index,1)

    },
    //点击取消时
    quxiao(){
      this.$refs.myForm.resetValidation()
      this.visible = false // 控制取消时小弹窗
      this.$emit('closedialog')
      console.log('点击了取消');
    },



    // 获取最新版本信息      
    getLatest(){
      this.loadingState = true;
      setTimeout(async() => {
        if(this.getadddata.apkName.value === undefined || this.getadddata.publishTypeid.value === undefined){
          this.loadingState = false;
          console.log('有东西没填');
          notifys('negative', `请先选择应用名称与发布类型再来获取最新的版本号`)
        }else{
          const { data: res} = await publishLatest({
              apkName: this.getadddata.apkName.key,
              publishType: this.getadddata.publishTypeid.key
          })
          console.log(res);
          if(res.code===20000){
              this.loadingState = false;
              this.getadddata.apkVersion = res.data.apk.apkVersion
              notifys('positive',res.message)
            }else{
              this.loadingState = false;
              notifys('negative',res.message)
            }
            // console.log('都填了');
          }
        },500)
        
    },
    /**
     * 提交订单时
     */
    addorder:makeDebounce(function(){ 
    //   const checkReportFiles= this.getadddata.checkReportFilesList
        const dcmReport = this.getadddata.dcmReportFilesList //获取apk文件
        console.log(dcmReport);
        this.$refs.myForm.validate().then( async outcome =>{
        if (outcome){
          if(dcmReport.length==0){
            notifys('negative',`尚未选取apk安装包`)
          }else{
            try {
              this.fileName = dcmReport[0].name
              const filedata = new FormData()
              filedata.append("apkName", this.getadddata.apkName.key) //应用名称
              filedata.append("apkVersion", this.getadddata.apkVersion) //版本号
              filedata.append("apkOsType", this.getadddata.osTypeid.key) // 系统类型
              filedata.append("publishType", this.getadddata.publishTypeid.key) //发布类型
              filedata.append("publishComment", this.getadddata.publishComment) //发布说明
              filedata.append("apkFile", dcmReport[0])
              this.alert = true
              const { data: res } = await publishAdd(filedata , event=>{
                  this.checkprogress = event.loaded/event.total
                  this.checkprogressLabel=((event.loaded/event.total)*100).toFixed(2) + '%'
                  // console.log(event);
              })
              console.log('点击了发布');
              console.log(res);
              // const a = false
              if(res.code===20000){
                notifys('positive',res.message)
                this.alert = false
                this.$emit('closedialog')
                this.$emit('ReleaseSuccess')
              }else{
                this.alert = false
                notifys('negative',res.message)
              }
            }catch (e) {
              this.alert = false
              console.log(e);
            }
          }
          
        }
      })
    },500)
  },
};
// const { data: res } = await axios({
//   method: 'post',
//   url: 'order/userOrder/information/save/',
//   data: filedata,
//   timeout:9999999999999999999, 
//   headers:{
//       token: sessionStorage.getItem("token")
//   }
// })
</script>

<style lang='less' scoped>
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
  width: 100%;
  margin-left: 0;
  margin-top: 0.1875rem;
}
.addbtn {
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
}
.xz{
  margin-left: .625rem;
}
.wenjian{
  font-size: 17px;
  font-weight: bold;
}
</style>
